body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Kalnia500';
  font-style: normal;
  line-height: normal;
  font-weight: 500;
  src:
    url('../src/assets/fonts/Kalnia-VariableFont_wdth.ttf') format('truetype');
}

@font-face {
  font-family: 'Kalnia600';
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  src:
    url('../src/assets/fonts/Kalnia-VariableFont_wdth.ttf') format('truetype');
}

@font-face {
  font-family: 'Kalnia700';
  font-style: normal;
  line-height: normal;
  font-weight: 700;
  src:
    url('../src/assets/fonts/Kalnia-VariableFont_wdth.ttf') format('truetype');
}

@font-face {
  font-family: 'Sans400';
  font-style: normal;
  line-height: normal;
  src:
    url('../src/assets/fonts/PTSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Sans700';
  font-style: normal;
  line-height: normal;
  src:
    url('../src/assets/fonts//PTSans-Bold.ttf') format('truetype');
}
